var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.forecastDays)?_c('v-container',[_c('v-row',[_c('v-col',[_c('h1',[_vm._v("3 Days Forecast")])])],1),_c('v-row',{staticClass:"d-flex justify-center"},_vm._l((_vm.forecastDays),function(forecast){return _c('v-col',{key:forecast.date,attrs:{"cols":"12","md":"6","lg":"4"}},[_c('ForecastCard',_vm._b({},'ForecastCard',{
          location: _vm.location,
          date: forecast.date,
          mintemp_c: forecast.mintemp_c,
          maxtemp_c: forecast.maxtemp_c,
          totalprecip_mm: forecast.totalprecip_mm,
          avghumidity: forecast.avghumidity,
          avgvis_km: forecast.avgvis_km,
          maxwind_kph: forecast.maxwind_kph,
          condition: forecast.condition,
          daily_chance_of_rain: forecast.daily_chance_of_rain,
          daily_chance_of_snow: forecast.daily_chance_of_snow,
          sunrise: forecast.sunrise,
          sunset: forecast.sunset,
        },false))],1)}),1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }